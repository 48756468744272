import { faClock } from "@awesome.me/kit-af809b8b43/icons/classic/regular";
import { Badge, Paper, Stack, Text } from "@flpstudio/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@typeform/embed/build/css/popup.css";

import { type QuizCalloutClickTakeQuizProperties, ampli } from "@/ampli";
import WomanGraphAnalysis from "@/assets/images/woman-graph-analysis.svg";
import { useAuth } from "@/hooks/use-auth";
import { TypeFormQuiz } from "./TypeFormQuiz";

type Props = {
  trackingLocation: QuizCalloutClickTakeQuizProperties["callout_location"];
};

export function PreRecommendationWidget(props: Props) {
  const { user } = useAuth();

  return (
    <Paper className="-mx-6 flex gap-4 rounded-none bg-gradient-to-r from-[#E2DFFF] to-[#F5EBF2] lg:mx-0 lg:rounded-lg">
      <Stack>
        <Badge color="violet.3" className="py-3 text-base/normal text-inherit">
          <FontAwesomeIcon icon={faClock} className="mr-1" />
          5-10 min
        </Badge>
        <Text className="font-semibold text-2xl">
          Quiz: Is your IT working for you?
        </Text>
        <Text>
          Take our short quiz to identify your company’s IT needs and risks.
          Based on your results, we’ll provide action items and a recommended
          GuideStack expert for a free consultation.
        </Text>
        <TypeFormQuiz
          buttonLabel="PRE_RECOMMENDATION"
          onClick={() =>
            ampli.quizCalloutClickTakeQuiz({
              user_status: user ? "Logged In" : "Logged Out",
              callout_location: props.trackingLocation,
            })
          }
          className="lg:self-start"
        />
      </Stack>
      <div className="ml-auto hidden items-center lg:flex">
        <img
          alt="woman analysing a graph"
          src={WomanGraphAnalysis}
          loading="lazy"
          width={134}
          height={134}
        />
      </div>
    </Paper>
  );
}
