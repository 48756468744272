import { Anchor, Group, Stack, Text } from "@flpstudio/design-system";
import { clsx } from "clsx/lite";
import { Link, generatePath } from "react-router";

import { type NextStepsClickViewFullProperties, ampli } from "@/ampli";
import type { QuizRecommendationDto } from "@/api/openapi";
import { useAuth } from "@/hooks/use-auth";
import { useBookingList } from "@/hooks/use-booking";
import { paths } from "~/paths";
import { RecommendedExpert } from "./RecommendedExpert";
import { RecommendedGuides } from "./RecommendedGuides";

type Props = {
  recommendation: QuizRecommendationDto;
  trackingLocation: NextStepsClickViewFullProperties["callout_location"];
};

export function PostRecommendationWidget(props: Props) {
  const { user } = useAuth();

  const { data: bookingListData } = useBookingList({ enabled: Boolean(user) });

  const isExpertRecommendationShown =
    !bookingListData?.hasUncancelledBookings &&
    props.recommendation.expertRecommendation;

  const userStatus = user ? "Logged In" : "Logged Out";
  const { firstName, lastName } =
    props.recommendation.expertRecommendation.expert;
  const expertName = `${firstName} ${lastName}`;

  return (
    <Stack>
      <Group>
        <Text className="font-semibold text-2xl/normal">IT next steps</Text>
        <Anchor
          component={Link}
          to={paths.itRecommendation}
          className="underline"
          onClick={() =>
            ampli.nextStepsClickViewFull({
              user_status: user ? "Logged In" : "Logged Out",
              callout_location: props.trackingLocation,
            })
          }
        >
          Full recommendation
        </Anchor>
      </Group>
      <Text>
        We personalized our recommendations based on the information you shared.
        Let’s take the next step toward solving your IT challenges!
      </Text>
      <div className="relative">
        <ul
          className={clsx(
            "my-0 list-none p-0",
            isExpertRecommendationShown &&
              "-mx-6 flex snap-x grid-cols-2 flex-nowrap gap-4 overflow-auto scroll-smooth px-6 lg:m-0 lg:grid lg:p-0",
          )}
        >
          {isExpertRecommendationShown && (
            <li className="flex w-5/6 flex-none snap-center scroll-mx-6 flex-col gap-4 rounded-lg border border-[--mantine-color-gray-3] border-solid bg-[--mantine-color-white] p-6 lg:w-full">
              <Text className="font-semibold text-xl/normal">
                Talk to a GuideStack expert
              </Text>
              <RecommendedExpert
                recommendedExpert={props.recommendation.expertRecommendation}
                onBookSessionClick={(bookingType) => {
                  if (bookingType === "INTRO_BOOKING") {
                    ampli.nextStepsClickBookFreeIntro({
                      user_status: userStatus,
                      expert_name: expertName,
                      callout_location: props.trackingLocation,
                    });

                    return;
                  }

                  ampli.nextStepsClickBookSession({
                    user_status: userStatus,
                    expert_name: expertName,
                    callout_location: props.trackingLocation,
                  });
                }}
              />
            </li>
          )}
          {props.recommendation.guideRecommendations && (
            <li
              className={clsx(
                "flex flex-none snap-center scroll-mx-6 flex-col gap-4 rounded-lg border border-[--mantine-color-gray-3] border-solid bg-[--mantine-color-white] p-6 lg:w-full",
                isExpertRecommendationShown && "w-5/6",
              )}
            >
              <Text className="font-semibold text-xl/normal">
                Explore our starter guides
              </Text>
              <RecommendedGuides
                recommendedGuides={props.recommendation.guideRecommendations}
                onClick={(urlSlug) =>
                  ampli.nextStepsClickGuide({
                    user_status: userStatus,
                    callout_location: props.trackingLocation,
                    url: generatePath(paths.documentation.viewer, {
                      urlSlug,
                    }),
                  })
                }
              />
            </li>
          )}
        </ul>
      </div>
    </Stack>
  );
}
