import { Button } from "@flpstudio/design-system";
import * as TypeForm from "@typeform/embed"; // Package is CommonJS
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import "@typeform/embed/build/css/popup.css";

import { useAuth } from "@/hooks/use-auth";
import { useITRecommendationTypeFormQuizMutation } from "@/hooks/use-it-recommendation-mutation";
import { paths } from "~/paths";
import { saveSubmissionId } from "./utils";

type Props = {
  className?: string;
  buttonLabel: "PRE_RECOMMENDATION" | "CLIENT_ASSESSMENT";
  onClick: () => void;
};

export function TypeFormQuiz(props: Props) {
  const { user } = useAuth();

  const itRecommendationTypeFormQuizMutation =
    useITRecommendationTypeFormQuizMutation();
  const navigate = useNavigate();

  const typeFormRef = useRef<TypeForm.Popup>();

  useEffect(() => () => typeFormRef.current?.unmount(), []);

  function handleOnClick() {
    const submissionId = crypto.randomUUID();

    typeFormRef.current = TypeForm.createPopup(
      import.meta.env.VITE_TYPEFORM_ID,
      {
        autoClose: true,
        hidden: {
          submission_id: submissionId,
          name: user ? `${user?.firstName} ${user?.lastName}` : "",
          email: user?.email || "",
          phone_number: "",
          utm_source: "",
        },
        onSubmit() {
          if (!user) {
            saveSubmissionId(submissionId);
          } else {
            itRecommendationTypeFormQuizMutation.mutate({ submissionId });
          }

          const MODAL_DELAY = 750;

          setTimeout(() => navigate(paths.itRecommendation), MODAL_DELAY);
        },
      },
    );

    typeFormRef.current.open();

    props.onClick();
  }

  const isPreRecommendation = props.buttonLabel === "PRE_RECOMMENDATION";

  return (
    <>
      <Button
        variant={isPreRecommendation ? "filled" : "outline"}
        onClick={handleOnClick}
        className={props.className}
      >
        {isPreRecommendation ? "Check your IT health" : "Take the quiz"}
      </Button>
    </>
  );
}
